import {IPropertyListDescriptor, PropertyDescriptorParsingType} from '../IPropertyDescriptor';
import {CSSValue, isIdentToken} from '../syntax/parser';
export const enum DISPLAY {
    NONE = 0,
    BLOCK = 1 << 1,
    INLINE = 1 << 2,
    RUN_IN = 1 << 3,
    FLOW = 1 << 4,
    FLOW_ROOT = 1 << 5,
    TABLE = 1 << 6,
    FLEX = 1 << 7,
    GRID = 1 << 8,
    RUBY = 1 << 9,
    SUBGRID = 1 << 10,
    LIST_ITEM = 1 << 11,
    TABLE_ROW_GROUP = 1 << 12,
    TABLE_HEADER_GROUP = 1 << 13,
    TABLE_FOOTER_GROUP = 1 << 14,
    TABLE_ROW = 1 << 15,
    TABLE_CELL = 1 << 16,
    TABLE_COLUMN_GROUP = 1 << 17,
    TABLE_COLUMN = 1 << 18,
    TABLE_CAPTION = 1 << 19,
    RUBY_BASE = 1 << 20,
    RUBY_TEXT = 1 << 21,
    RUBY_BASE_CONTAINER = 1 << 22,
    RUBY_TEXT_CONTAINER = 1 << 23,
    CONTENTS = 1 << 24,
    INLINE_BLOCK = 1 << 25,
    INLINE_LIST_ITEM = 1 << 26,
    INLINE_TABLE = 1 << 27,
    INLINE_FLEX = 1 << 28,
    INLINE_GRID = 1 << 29
}

export type Display = number;

export const display: IPropertyListDescriptor<Display> = {
    name: 'display',
    initialValue: 'inline-block',
    prefix: false,
    type: PropertyDescriptorParsingType.LIST,
    parse: (tokens: CSSValue[]): Display => {
        return tokens.filter(isIdentToken).reduce((bit, token) => {
            return bit | parseDisplayValue(token.value);
        }, DISPLAY.NONE);
    }
};

const parseDisplayValue = (display: string): Display => {
    switch (display) {
        case 'block':
            return DISPLAY.BLOCK;
        case 'inline':
            return DISPLAY.INLINE;
        case 'run-in':
            return DISPLAY.RUN_IN;
        case 'flow':
            return DISPLAY.FLOW;
        case 'flow-root':
            return DISPLAY.FLOW_ROOT;
        case 'table':
            return DISPLAY.TABLE;
        case 'flex':
        case '-webkit-flex':
            return DISPLAY.FLEX;
        case 'grid':
        case '-ms-grid':
            return DISPLAY.GRID;
        case 'ruby':
            return DISPLAY.RUBY;
        case 'subgrid':
            return DISPLAY.SUBGRID;
        case 'list-item':
            return DISPLAY.LIST_ITEM;
        case 'table-row-group':
            return DISPLAY.TABLE_ROW_GROUP;
        case 'table-header-group':
            return DISPLAY.TABLE_HEADER_GROUP;
        case 'table-footer-group':
            return DISPLAY.TABLE_FOOTER_GROUP;
        case 'table-row':
            return DISPLAY.TABLE_ROW;
        case 'table-cell':
            return DISPLAY.TABLE_CELL;
        case 'table-column-group':
            return DISPLAY.TABLE_COLUMN_GROUP;
        case 'table-column':
            return DISPLAY.TABLE_COLUMN;
        case 'table-caption':
            return DISPLAY.TABLE_CAPTION;
        case 'ruby-base':
            return DISPLAY.RUBY_BASE;
        case 'ruby-text':
            return DISPLAY.RUBY_TEXT;
        case 'ruby-base-container':
            return DISPLAY.RUBY_BASE_CONTAINER;
        case 'ruby-text-container':
            return DISPLAY.RUBY_TEXT_CONTAINER;
        case 'contents':
            return DISPLAY.CONTENTS;
        case 'inline-block':
            return DISPLAY.INLINE_BLOCK;
        case 'inline-list-item':
            return DISPLAY.INLINE_LIST_ITEM;
        case 'inline-table':
            return DISPLAY.INLINE_TABLE;
        case 'inline-flex':
            return DISPLAY.INLINE_FLEX;
        case 'inline-grid':
            return DISPLAY.INLINE_GRID;
    }

    return DISPLAY.NONE;
};
